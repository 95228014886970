import React from "react";
import { navigate, PageProps } from "gatsby";
import { Theme, makeStyles, useMediaQuery } from "@material-ui/core";
import { LayoutOnBoarding, SEO } from "@components";

import { Route } from "@interfaces";

import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { useAuth } from "@hooks";

import { FAILED_PAY_ASTRONAUT } from "images/GoogleCloud";
import PaymentStatus from "@components/Services/PaymentStatus/PaymentStatus";

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery("(max-width: 1270px)");

  return (
    <>
      {isMobile ? (
        <LayoutMobileInvestments activeServices>
          <>
            <PaymentStatus
              image={FAILED_PAY_ASTRONAUT}
              titleText={"ALGO SALIÓ MAL!"}
              descriptionText={
                "No te hemos cobrado, pero ocurrió un error y deberás intentar nuevamente"
              }
              backgroundColorCode={"#0D3038"}
              backRouteFunction={() => navigate(Route.services)}
              showMail={false}
            />
          </>
        </LayoutMobileInvestments>
      ) : (
        <>
          <SEO />
          <LayoutOnBoarding>
            <>
              <PaymentStatus
                image={FAILED_PAY_ASTRONAUT}
                titleText={"ALGO SALIÓ MAL!"}
                descriptionText={
                  "No te hemos cobrado, pero ocurrió un error y deberás intentar nuevamente"
                }
                backgroundColorCode={"#0D3038"}
                backRouteFunction={() => navigate(Route.services)}
                showMail={false}
              />
            </>
          </LayoutOnBoarding>
        </>
      )}
    </>
  );
};

export default SaveByRokinPage;
